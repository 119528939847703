/*eslint-disable */
import {bus} from '@/main';  
import toast from '@/services/toast';
import axios from 'axios';
import root from '@/_helpers/roots';
import videoRecording from '@/components/private/video-recording-data/index.vue';
import updateProfileInfo from '@/components/private/update-profile-info-popup/index.vue';
import { Datetime } from 'vue-datetime'
import Multiselect from 'vue-multiselect'
import blurbForAppointment from '../blurb-for-appointment/index.vue';
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
let appointmentApiUrl = root.appointmentApiUrl.link;
import moment from 'moment';
export default {
  name: 'create-appointmet-form',
  components: {
    datetime: Datetime,
    'video-record' : videoRecording,
    'update-profile-popup' : updateProfileInfo,
    Multiselect,
    'blurb-for-appointment' : blurbForAppointment
  },
  props: [],
  data () {
    return {
      full_name:'',
      dob:'',
      height:'',
      weight:'',
      blood_type:'',
      disease:'',
      symptoms:'',
      sendSymptomsArr:[],
      record:'',
      submitted:false,
      selfUserDetailData:{},
      datetime:'',
      subcategory:'',
      selfUserInfo:{},
      symptomsArr:[
        {name:'Fever', code:'Fever'},
        {name:'Acne', code:'Acne'},
        {name:'Blocked Nose', code:'Blocked Nose'},
        {name:'Runny Nose', code:'Runny Nose'},
        {name:'Itching', code:'Itching'},
        {name:'Headache', code:'Headache'},
        {name:'Vomiting', code:'Vomiting'},
        {name:'Constipation', code:'Constipation'},
        {name:'Throat pain', code:'Throat pain'},
        {name:'Rashes', code:'Rashes'},
        {name:'Sneezing', code:'Sneezing'},
        {name:'Hairfall', code:'Hairfall'},
        {name:'Abdominal Pain', code:'Abdominal Pain'},
        {name:'Spots On Skin', code:'Spots On Skin'},
        {name:'Obesity', code:'Obesity'},
        {name:'Gas', code:'Gas'},
        {name:'Erectile Dysfunction', code:'Erectile Dysfunction'},
        {name:'Bone Pain', code:'Bone Pain'},
        {name:'Dry Skin', code:'Dry Skin'},
      ],
      uploadedHRList:[],
      minDatetime:new Date(),
      medicalRecordsField:true,
      comment:'',
      videRecordState:false,
      videRecordData:'',
      uploadedVideoData:'',
      updatedProfilePopup:false,
      appoinmentWithID:'',
      gender:'',
      myFavDoctor:false,
      draftSymptoms:'',
      draftDisease:'',
      reBookingAppointment:false,
      available_of_day:[],
      available_of_start_time:'0',
      available_of_end_time:'24',
      available_schedule:null,
      uploadedVideoLink:null,
      appointmentId:'',
      blurbOfAppointmentState:false
    }
  },
  computed: {
  },
  mounted () {
    this.appoinmentWithID = this.$route.params.doctorID
    this.$store.dispatch('getDoctorDetailByIdFun', {id:this.appoinmentWithID}).then((res) => {
      this.appointmentInfo = res.data.data.data.latest_appointment_details
      this.myFavDoctor = res.data.data.data.is_favourite
      this.available_schedule = res.data.data.data.schedule
      if(this.available_schedule){
        this.available_of_day = this.available_schedule.available_of_day_int
        this.available_of_start_time = moment(this.available_schedule.available_of_start_time).format('HH')
        this.available_of_end_time = moment(this.available_schedule.available_of_end_time).format('HH')
      }
      if(this.appointmentInfo){
        this.symptoms = this.appointmentInfo.symptoms
        this.disease = this.appointmentInfo.disease
        this.draftSymptoms = this.appointmentInfo.symptoms
        this.draftDisease = this.appointmentInfo.disease
        this.reBookingAppointment = true
      }
    })
    this.$store.dispatch('getSelfUserInfoApiFun').then((res)=>{
      this.selfUserInfo = res.data.data
      this.full_name = this.selfUserInfo.first_name + ' ' + this.selfUserInfo.last_name
      this.dob = this.selfUserInfo.dob
      this.height = this.selfUserInfo.profile.height
      this.weight = this.selfUserInfo.profile.weight
      this.blood_type = this.selfUserInfo.profile.blood_type
      this.gender = this.selfUserInfo.gender
    })
    this.$store.dispatch('getHelthRecordsListApiFun').then((res)=>{
      this.uploadedHRList = res.data.data
      if(this.uploadedHRList.length == 0){
        this.medicalRecordsField = false
      }
      return
    }).catch((err)=>{

    })
    bus.$on('videRecordDataBus', (data) => {
      this.videRecordData = data
      this.renderUploadVideo(data)
    });
    bus.$on('videRecordStateBus', (data) => {
      this.videRecordState = data
    });
    bus.$on('blurbOfAppointmentStateBus', (data) => {
      this.blurbOfAppointmentState = data
    });
  },
  methods: {
    blurbTextPopupOpened(){
      this.blurbOfAppointmentState = true
      document.body.classList.add('popup_open_body')
    },
    renderUploadVideo(videRecordData){
        var __self = this
        var blob = new Blob(videRecordData, {type: 'video/webm'})
        var reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = function() {
          var videoBase64 = reader.result; 
          __self.uploadedVideoLink = videoBase64; 
        }
    },
    recordDataGetMethod(){
      if(this.record){
        let data = this.uploadedHRList.find(item => {
          if(item.id == this.record){
            this.full_name = item.first_name + ' ' + item.last_name
            this.dob = item.dob
            this.height = item.height
            this.weight = item.weight
            this.blood_type = item.blood_type
            this.gender = item.gender
          }
        });
        return data
      }else{
        this.full_name = this.selfUserInfo.first_name + ' ' + this.selfUserInfo.last_name
        this.dob = this.selfUserInfo.profile.dob
        this.height = this.selfUserInfo.profile.height
        this.weight = this.selfUserInfo.profile.weight
        this.blood_type = this.selfUserInfo.profile.blood_type
        this.gender = this.selfUserInfo.gender
        if(this.draftSymptoms){
          this.symptoms = this.draftSymptoms
        }
        if(this.draftDisease){
          this.disease = this.draftDisease
        }
      }
    },
    createAppointmentFormBeforeSubmit(){
      if(this.videRecordData){
        var __self = this
        var blob = new Blob(this.videRecordData, {type: 'video/webm'})
        __self.uploadedVideoLink = blob; 
        var reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = function() {
          var videoBase64 = reader.result; 
          __self.uploadedVideoData = videoBase64.replace(/^data:video\/(webm);base64,/, "")
        }
      }
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted = true;
          for(var i = 0; i < this.symptoms.length; i++){
            this.sendSymptomsArr.push(this.symptoms[i].name)
          }
          if(this.videRecordData){
            setTimeout(()=>{
              let data = {
                full_name: this.full_name,
                dob: this.dob,
                height: this.height,
                weight: this.weight,
                blood_type: this.blood_type,
                gender:this.gender,
                disease: this.disease,
                symptoms:  this.symptoms,
                medical_record:this.record,
                doctor_id:this.appoinmentWithID,
                appointment_time:this.datetime,
                video_file : this.uploadedVideoData,
                is_favourite:this.myFavDoctor
              }
              if(this.comment){
                data.message = this.comment;
              }
              axios.post(appointmentApiUrl, data).then((res)=>{
                let notificationPayload = { 
                  type:'appointment_created',
                  data_id:res.data.data.id,
                  message:"Appointment has been created"
                }
                this.$store.dispatch('postNotificationFunc', notificationPayload)
                this.appointmentId = res.data.data.id 
                this.blurbTextPopupOpened();      
                // this.$router.push({ name: 'paymentCheckout',  params: { appointmentId: res.data.data.id } })
                this.submitted =  false
              }).catch((err)=>{
                toast.error(err.data.msg || "Request Failed");
                this.submitted =  false
              })
            },100)
          }else{
            let data = {
              full_name: this.full_name,
              dob: this.dob,
              height: this.height,
              weight: this.weight,
              gender:this.gender,
              blood_type: this.blood_type,
              disease: this.disease,
              symptoms: this.symptoms,
              medical_record:this.record,
              doctor_id:this.appoinmentWithID,
              appointment_time:this.datetime,
              is_favourite:this.myFavDoctor
            }
            if(this.comment){
              data.message = this.comment;
            }
            axios.post(appointmentApiUrl, data).then((res)=>{
              let notificationPayload = { 
                type:'appointment_created',
                data_id:res.data.data.id,
                message:"Appointment has been created"
              }
              this.$store.dispatch('postNotificationFunc', notificationPayload)       
              this.appointmentId = res.data.data.id   
              this.blurbTextPopupOpened();    
              // this.$router.push({ name: 'paymentCheckout',  params: { appointmentId: res.data.data.id } })
              this.submitted =  false
            }).catch((err)=>{
              toast.error(err.data.msg || "Request Failed");
              this.submitted =  false
            })
          }
        return;
        }
      })
    },
    closeCreateAppointmentModal(){
      bus.$emit('bookAppointmentModalBus', false)
      document.body.classList.remove('popup_open_body')
    },
    videoRecordingPopupOpen(){
      this.uploadedVideoLink = null
      window.scrollTo(0, 0)
      document.body.classList.add('popup_open_body')
      this.videRecordState = true
    }
  },
  beforeRouteLeave: function (to, from, next) {
    document.body.classList.remove('popup_open_body')
    next();
  },
}